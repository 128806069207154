.dashGraphContainer {
    @apply grid xl:grid-cols-2 gap-4 w-full;
}

.dashGraph {
    @apply bg-white relative overflow-auto p-4 min-h-96 rounded-md shadow-md border border-lightGray;
}

.dashGraphLarge {
    @apply xl:col-span-2;
}

.dashHeader {
    @apply text-cleanSlate;
}

h3.dashHeader {
    @apply text-cleanSlate pt-6 pb-3;
}
