/* stylelint-disable at-rule-no-vendor-prefix, property-no-vendor-prefix */
.loader {
    border-top-color: rgb(0 106 113) !important;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

.error {
    @apply text-red-700 font-bold italic;
}

@-webkit-keyframes spinner {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/* stylelint-enable at-rule-no-vendor-prefix, property-no-vendor-prefix */
