/* Home Page Assertion */

.home h1,
.home h2,
.home h3,
.home h4,
.home h5 {
    @apply font-instrument text-cleanSlate;
}

.home h2 {
    @apply text-lg;
}

.home h3 {
    @apply font-medium text-cleanSlate;
}

/* Home Empty */

.homeEmpty {
    @apply -mt-4;
}

/* Home Stats */

.homeStats {
    @apply bg-transparent pt-0;

    border-radius: 0.375rem;
}

/* Home To Do List */

.homeTodoLinkList {
    @apply mt-3 text-sm;
}

.rightPanelItem {
    @apply relative bg-shadeStoneGray p-[17px] rounded-lg my-[15px];
}

/* END Home page responsive */
