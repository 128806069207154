.container {
    @apply py-0 pl-0 lg:pl-[64px] min-h-[91vh];
}

.headerContainer {
    @apply flex items-center justify-between px-0;
}

.headline {
    @apply font-jazmin text-2xl;
}

.headerControls {
    @apply mt-0 flex md:ml-4;
}

.pageDescription {
    @apply text-sm text-cleanSlate mt-2;
}

.subPageMainHeadline {
    @apply text-cleanSlate mr-2 cursor-pointer;
}

.subPageSubHeadline {
    @apply text-cleanSlate;
}

.subPageDescription {
    @apply text-cleanSlate px-0 mt-0 mb-9;
}

.divider {
    @apply p-0 my-0 mx-5 mt-4 border-lightGray;
}
